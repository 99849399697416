<template>
  <v-bottom-navigation
  fixed
  v-model="value"
  background-color="black"
  dark
  class="bottom-nav"
  >
    <!-- :background-color="color" -->
  <template v-for="item in menu">
    <v-btn :key="`bot_nav_${item.text}`" :to="item.path" v-if="item.path !== '/search'" exact>
      <!-- <span>{{item.text}}</span> -->
      <!-- shift -->
      <v-badge
        v-if="item.text == 'Proposals' && notifications"
        color="primary"
        dot
      >
        <v-icon>{{item.icon}}</v-icon>
      </v-badge>
      <template v-else>
        <v-icon>{{item.icon}}</v-icon>
      </template>
    </v-btn>
    <v-btn icon class="search" :key="`bot_nav_${item.text}`" :to="item.path"  v-else :class="{'bg-loop': $route.path !== '/search', 'grey lighten-2': $route.path == '/search'}" :color="$route.path === '/search' ? 'white': 'transparent'" >
      <!-- @click="launchSearch" -->
      <!-- <span>{{item.text}}</span> -->
      <!-- shift -->
      <v-icon :color="$route.path == '/search' ? 'grey': 'white'" class="text-shadow">{{item.icon}}</v-icon>
    </v-btn>
  </template>

  </v-bottom-navigation>
</template>



<script>
import {db} from '@/firebase';

export default {
  name: 'navLoggedMobile',
  computed:{
    menu: function(){return this.$store.state.mobile_menu},
    proposals: function (){ return this.$store.state.proposals},
    proposalsLoaded: function (){ return this.$store.state.proposalsLoaded},
    meetings: function (){ return this.$store.state.meetings},
    meetingsLoaded: function (){ return this.$store.state.meetingsLoaded},
    notifications: function(){
      // return this.proposals.findIndex(item => item.data.turn === 'client')
      // return this.proposals.reduce(function(n, val) {
      //   return n + (val.data.turn === 'client');
      //   }, 0);
      return this.proposals.length > 0
    },
  },
  data: () => ({
    value:0,
    photoURL: null,
    user:null,
    userId: null,
    dialog: false,
  }),
  methods:{
    launchSearch(){
      this.$store.dispatch('launchSearch', true);
    },
    setupUser(){
      let vThis = this;
      this.$store.dispatch('getCurrentUser').then(user => {
        vThis.user = user.data;
        vThis.userId = user.userId;
        vThis.getMeetings(user.userId);
        vThis.getProposals(user.userId)
      })
    },
    //load meetingsLoaded
    getProposals(userId){
      let vThis = this;
      this.$store.dispatch('getProposals', {userId: userId, flag: 'active'});
    },
    getMeetings(userId){
      let vThis = this;
      this.$store.dispatch('getMeetings', {userId: userId, flag: 'active'});
    },

  },
  mounted(){
    this.setupUser();
  }
};

// color () {
//   switch (this.value) {
//     case 0: return 'purple'
//     case 1: return 'secondary darken-1'
//     case 2: return 'black'
//     case 3: return 'teal'
//     case 4: return 'indigo'
//     default: return 'blue-grey'
//   }
// }
</script>



<style lang="scss" scoped>
.v-bottom-navigation.bottom-nav{

    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;

  .search.v-btn{
    margin-top:-24px;
    // background-color: black!important;
    border-radius: 50%;
    min-width: 56px!important;
    max-width: 56px!important;
  }
}
</style>
